import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import {
	createContext,
	RefObject,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { AutoTestState, StatusStateInText, WorkspaceStatus } from '../enums'
import { addCommitTestBuild, addCommitTestBuildStep, updateCommitTestBuildStatus } from '../redux/commitBuildLogsSlice'
import { b64DecodeUnicode, b64EncodeUnicode } from '../shared/helpers/base64Encode&Decode'
import playBackendRequestHandler from './playBackendRequestHandler'


export const WebsocketContext = createContext({
	wsClient: {} as RefObject<WebSocket | null>,
	isWSConnectionEstablished: false,
	wsConnectionMessage: "",
})

export function useWebsocketContext() {
	return useContext(WebsocketContext)
}

export function useInterval(callback: () => void, delay: number | null) {
	const savedCallback = useRef(callback)

	// Remember the latest callback if it changes.
	useLayoutEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		// Note: 0 is a valid value for delay.
		if (!delay && delay !== 0) {
			return
		}

		// Execute first call immediately
		savedCallback.current()

		// Set the delayed interval execution
		const id = setInterval(() => savedCallback.current(), delay)
		// eslint-disable-next-line consistent-return
		return () => clearInterval(id)
	}, [delay])
}

export function useForkUrlWSListener(gameSlug: string, challengeSlug: string): string {
	const { wsClient } = useWebsocketContext()
	const [forkUrlWSResponse, setForkUrlWSResponse] = useState('')

	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			const { response } = JSON.parse(e.data)
			if (response) {
				const {
					sshUrl,
					challengeSlug: responseChallengeSlug,
					gameSlug: responseGameSlug,
				} = JSON.parse(response) // fix for first response
				if (
					sshUrl &&
					challengeSlug === responseChallengeSlug &&
					gameSlug === responseGameSlug
				) {
					setForkUrlWSResponse(sshUrl)
				}
			}
		},
		[challengeSlug, gameSlug]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])

	return forkUrlWSResponse
}

export function useChallengeSolutionForkUrlWSListenerWithGameAndChallengeSlug(gameSlug: string, challengeSlug: string): string {
	const { wsClient } = useWebsocketContext()
	const [challengeSolutionSSHUrlResponse, setChallengeSolutionSSHUrlResponse] = useState('')

	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			const { response } = JSON.parse(e.data)
			if (response) {
				const {
					challengeSolutionSSHUrl,
					challengeSlug: responseChallengeSlug,
					gameSlug: responseGameSlug,
				} = JSON.parse(response) // fix for first response
				if (
					challengeSolutionSSHUrl &&
					challengeSlug === responseChallengeSlug &&
					gameSlug === responseGameSlug
				) {
					setChallengeSolutionSSHUrlResponse(challengeSolutionSSHUrl)
				}
			}
		},
		[challengeSlug, gameSlug]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])

	return challengeSolutionSSHUrlResponse
}

export function useChallengeSolutionForkUrlWSWithoutGameAndChallengeSlug(): any {
	const { wsClient } = useWebsocketContext()
	const [challengeSolutionSSHUrlResponse, setChallengeSolutionSSHUrlResponse] = useState('')

	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			const { response } = JSON.parse(e.data)
			if (response) {
				const {
					challengeSolutionSSHUrl, sshUrl
				} = JSON.parse(response) // fix for first response
				if (
					challengeSolutionSSHUrl || sshUrl
				) {
					setChallengeSolutionSSHUrlResponse(challengeSolutionSSHUrl || sshUrl)
				}
			}
		},
		[]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])

	return { challengeSolutionSSHUrlResponse, setChallengeSolutionSSHUrlResponse }
}

export function useReceiveCommitFromWS(
	gameSlug: string,
	challengeSlug: string
) {
	const { wsClient } = useWebsocketContext()
	const [commitFromWS, setCommitFromWS] = useState<Commit | null>(null)
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			const { response } = JSON.parse(e.data)
			if (response) {
				const parsedResponse: any = JSON.parse(response) // fix for first response
				if (
					parsedResponse && parsedResponse.sha &&
					challengeSlug === parsedResponse.challengeSlug &&
					gameSlug === parsedResponse.gameSlug &&
					parsedResponse.commitDate &&
					parsedResponse.message
				) {
					const commit: Commit = {
						sha: parsedResponse.sha,
						commitDate: parsedResponse.commitDate,
						message: parsedResponse.message,
						gameSlug,
						challengeSlug,
						status: {
							build_id: 0,
							status_state: StatusStateInText.ReadyForTest,
							build_output: "",
							commit_id: parsedResponse.sha,
							build_time: 0,
							start_time: 0
						}
					}
					setCommitFromWS(commit)
				}
			}
		},
		[challengeSlug, gameSlug]
	)

	useEffect(() => {
		const ws = wsClient?.current
		ws?.addEventListener('message', myListen)

		return () => ws?.removeEventListener('message', myListen)
	}, [wsClient, myListen])

	return commitFromWS
}

export function useReceiveCommitListFromWS(
	gameSlug: string,
	challengeSlug: string,
	latestChallengeAttempt: ChallengeAttempt
) {
	const { wsClient } = useWebsocketContext()
	const [commitListFromWS, setValidCommitListFromWS] = useState<Commit[]>([])
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			try {
				if (Array.isArray(JSON.parse(JSON.parse(e.data).response))) {
					const parsedCommitListFromWS = JSON.parse(JSON.parse(e.data).response) // this is  commits
					if (
						parsedCommitListFromWS[0].challengeSlug === challengeSlug &&
						parsedCommitListFromWS[0].gameSlug === gameSlug &&
						parsedCommitListFromWS[0].commitdate &&
						parsedCommitListFromWS[0].message &&
						parsedCommitListFromWS[0].sha &&
						latestChallengeAttempt // this is to get latest challenge attempt create and end date 
					) {
						const validCommits: any = []
						parsedCommitListFromWS.forEach((commit: any) => {
							const commitDate: Date = new Date(commit.commitdate)
							const challengeAttemptCreated: Date = new Date(latestChallengeAttempt.created_at)
							const challengeAttemptEnd: Date = new Date(latestChallengeAttempt.ends_at)
							if (challengeAttemptCreated < commitDate && commitDate < challengeAttemptEnd)
								validCommits.push(
									{
										sha: commit.sha,
										commitDate: commit.commitdate,
										message: commit.message,
										gameSlug: commit.gameSlug,
										challengeSlug: commit.challengeSlug,
									}
								)
						})
						if (validCommits.length > 0) {
							setValidCommitListFromWS(validCommits)
						}
					}
				}
			} catch {
				// 
			}
		},
		[challengeSlug, gameSlug, latestChallengeAttempt]
	)

	useEffect(() => {
		const ws = wsClient?.current
		ws?.addEventListener('message', myListen)

		return () => ws?.removeEventListener('message', myListen)
	}, [wsClient, myListen])

	return commitListFromWS;
}

export function useReceiveTestBuildStatusFromWS() {
	const dispatch = useDispatch()
	const [testBuildStatus, setTestBuildStatus] = useState<CommitBuildStatus | null>(null)
	const { wsClient } = useWebsocketContext()
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			try {
				const {
					buildId,
					commitId,
					gameSlug,
					challengeSlug,
					status,
					totalSteps,
					testDuration,
					exception,
				} = JSON.parse(JSON.parse(e.data).notification || JSON.parse(e.data).error)
				const commitBuild: CommitBuild = {
					buildId,
					commitId,
					gameSlug,
					challengeSlug,
					status,
					testDuration,
					buildOutputMode: 0,
					stages: {
						stageNumber: 0,
						steps: [],
						totalSteps
					}
				}
				if (exception && gameSlug && challengeSlug && commitId) {
					commitBuild.status = StatusStateInText.Error
					dispatch(updateCommitTestBuildStatus(commitBuild))
					setTestBuildStatus({
						buildId,
						commitId,
						gameSlug,
						challengeSlug,
						status: StatusStateInText.Error,
						totalSteps,
						testDuration,
					})
				} else if (
					status &&
					(totalSteps || totalSteps === 0) &&
					buildId &&
					gameSlug &&
					challengeSlug &&
					commitId
				) {
					dispatch(updateCommitTestBuildStatus(commitBuild))
					setTestBuildStatus({
						buildId,
						commitId,
						gameSlug,
						challengeSlug,
						status,
						totalSteps,
						testDuration,
					})
				}
			} catch {
				// Not My message if its not JSON
			}
		},
		[dispatch]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])

	return testBuildStatus
}

export function useCommitBuild() {
	const dispatch = useDispatch()
	const { wsClient } = useWebsocketContext()
	const myListen = useCallback((e: MessageEvent<any>) => {
		const { notification, general } = JSON.parse(e.data)
		if (notification) {
			const { buildOutputMode, gameSlug, challengeSlug, buildId, commitId } = JSON.parse(notification)
			const commitBuild: CommitBuild = {
				buildId,
				commitId,
				gameSlug,
				challengeSlug,
				status: 'running',
				testDuration: 0,
				buildOutputMode,
				stages: {
					stageNumber: 0,
					steps: [],
					totalSteps: 0
				}
			}
			if (buildOutputMode === 1 && gameSlug &&
				challengeSlug && buildId && commitId
			) {
				const defaultStep = {
					stepName: '',
					stepNumber: 0,
					logs: [
						{
							log: 'No test output is available for this challenge',
							position: 0,
							id: new Date().getTime(),
						},
					],
				}
				commitBuild.stages.steps.push(defaultStep)
			}
			dispatch(addCommitTestBuild(commitBuild))
		}
		else if (general) {
			const { log, position, buildId, stageNumber, stepNumber, gameSlug, challengeSlug, commitId, stepName } = JSON.parse(general)
			if (
				log &&
				buildId &&
				stageNumber &&
				stepNumber &&
				gameSlug &&
				challengeSlug &&
				commitId &&
				stepName
			) {
				const testBuild: CommitBuild = {
					buildId,
					commitId,
					gameSlug,
					challengeSlug,
					stages: {
						stageNumber,
						steps: [{
							stepNumber,
							stepName,
							logs: [{ log, position, id: new Date().getTime() }],
						}]
					},
				}
				dispatch(addCommitTestBuildStep(testBuild))
			}
		}
	}, [dispatch])

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => ws?.removeEventListener('message', myListen)
	}, [myListen, wsClient])
}

export function useReward(gameSlug: string, challengeSlug: string) {
	return useQuery({
		queryKey: [`reward-${gameSlug}-${challengeSlug}`],
		queryFn: async (): Promise<AxiosResponse<any>> => {
			const resp = await playBackendRequestHandler(
				'reward',
				undefined,
				undefined,
				`${gameSlug}/challenge/${challengeSlug}/reward/`
			)
			return resp
		},
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useChallengeAttempt(gameSlug: string, challengeSlug: string) {
	return useQuery({
		queryKey: [`challengeAttempt-${gameSlug}-${challengeSlug}`],
		queryFn: async (): Promise<AxiosResponse<ChallengeAttempt>> =>
			playBackendRequestHandler(
				'challengeAttempt',
				undefined,
				undefined,
				`${gameSlug}/challenge/${challengeSlug}/attempt/`
			),
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useFetchLatestFiveChallengeAttempts(gameSlug: string, challengeSlug: string) {
	return useQuery({
		queryKey: [`challengeAttempts-${gameSlug}-${challengeSlug}`],
		queryFn: async (): Promise<AxiosResponse<ChallengeAttempt[]>> =>
			playBackendRequestHandler(
				'challengeAttempts',
				undefined,
				undefined,
				`${gameSlug}/challenge/${challengeSlug}/attempts/`
			),
		gcTime: 10000,
		staleTime: 10000
	})
}

export function useGames() {
	return useQuery({
		queryKey: ['games'],
		queryFn: async(): Promise<AxiosResponse<Game[]>> => playBackendRequestHandler('games'),
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useGame(gameSlug: string) {
	return useQuery({
		queryKey: [`game-${gameSlug}`],
		queryFn: async(): Promise<AxiosResponse<Game>> => playBackendRequestHandler('game', undefined, undefined, gameSlug),
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useGameChallenges(gameSlug: string) {
	return useQuery({
		queryKey: [`game-${gameSlug}-challenges`],
		queryFn: async (): Promise<AxiosResponse<Challenge[]>> =>
			playBackendRequestHandler(
				'gameChallenges',
				undefined,
				undefined,
				`${gameSlug}/challenges/`,
			),
		gcTime: 0,
		staleTime: 0,
		refetchOnMount: true,
	})
}

export function usePublishedAppList(gameSlug: string, challengeSlug: string) {
	return useQuery({
		queryKey: [`publishedAppList`],
		queryFn: async (): Promise<AxiosResponse<PublishedApp[]>> =>
			playBackendRequestHandler(
				'publishedAppList',
				undefined,
				undefined,
				`${gameSlug}/challenge/${challengeSlug}/battle/list/`
			),
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useChallenge(gameSlug: string, challengeSlug: string) {
	return useQuery({
		queryKey: [`game-${gameSlug}-challenge-${challengeSlug}`],
		queryFn: async (): Promise<AxiosResponse<Challenge>> =>
			playBackendRequestHandler(
				'challenge',
				undefined,
				undefined,
				`${gameSlug}/challenge/${challengeSlug}`
			),
		gcTime: 0,
		staleTime: 0, 
		refetchOnMount: true,
	})
}

export function useVulnerabilities(catalog: boolean) {
	const search = (new URLSearchParams(window.location.search)).get('search');
	let query: any;
	if (search && catalog) {
		query = `?search=${search}&catalog=${catalog}`
	}
	else if (search) {
		query = `?search=${search}`
	}
	else if (catalog) {
		query = `?catalog=${catalog}`
	}
	else {
		query = undefined
	}
	return useQuery({
		queryKey: [`vulnerabilities`],
		queryFn: async (): Promise<AxiosResponse<Vulnerability[]>> => {
			const resp = await playBackendRequestHandler(
				'vulnerabilities',
				undefined,
				undefined,
				query
			)
			return resp
		},
		refetchOnWindowFocus: false,
	})
}

export function useVulnerabilityDetails(vulnerabilitySlug: string, challengeId: string) {
	return useQuery({
		queryKey: [`vulnerability-${vulnerabilitySlug}-id-${challengeId}`],
		queryFn: async (): Promise<AxiosResponse<Vulnerability>> =>
			playBackendRequestHandler(
				'vulnerability',
				undefined,
				undefined,
				`${vulnerabilitySlug}/id/${challengeId}`
			),
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useTop10Players(gameSlug: string) {
	return useQuery({
		queryKey: [`game-${gameSlug}-top10players`],
		queryFn: async(): Promise<AxiosResponse<Top10PlayerScore[]>> => {
		const resp = await playBackendRequestHandler(
			'top10players',
			undefined,
			undefined,
			`${gameSlug}/top10players/`
		)
			return resp
	  },
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useCompany() {
	return useQuery({
		queryKey: [`company`],
		queryFn: async(): Promise<AxiosResponse<Company>> => {
		const resp = await playBackendRequestHandler(
			'company',
			undefined,
			undefined,
			undefined
		)
			return resp
	},
	refetchOnWindowFocus: false,
	})
}

export function useCompanyGamesActivities() {
	return useQuery({
		queryKey: [`company-games-activities`],
		queryFn: async (): Promise<AxiosResponse<CompanyGamesActivities>> => {
			const resp = await playBackendRequestHandler(
				'companyGamesActivities',
				undefined,
				undefined,
				undefined,
			)
			return resp
		},
		refetchOnWindowFocus: false,
	})
}

export function useStartHeres() {
	return useQuery({
	  queryKey: ['start-heres'],
	  queryFn: async (): Promise<AxiosResponse<Challenge[]>> =>
		playBackendRequestHandler('startHeres', undefined, undefined, undefined),
	  refetchOnWindowFocus: false,
	});
  }
  
export function useCompanyPlayersActivities() {
	return useQuery({
		queryKey: [`company-players-activities`],
		queryFn: async (): Promise<AxiosResponse<CompanyPlayersActivities>> => {
			const resp = await playBackendRequestHandler(
				'companyPlayersActivities',
				undefined,
				undefined,
				undefined,
			)
			return resp
		},
		refetchOnWindowFocus: false,
	})
}

export function useCreateWorkspace(vulnerabilitySlug: string, challengeId: number) {
	return useQuery({
		queryKey:[`create-workspace`],
		queryFn: async(): Promise<AxiosResponse<string>> => {
		const resp = await playBackendRequestHandler(
			'createWorkspaceForVulnerability',
			undefined,
			undefined,
			`${vulnerabilitySlug}/id/${challengeId}/workspace/create`
		)
			return resp
	},
	refetchOnWindowFocus: false,
  })
}

export function useCompanyPlayerGitHistory() {
	return useQuery({
		queryKey: [`company-player-git-history`],
		queryFn: async(): Promise<AxiosResponse<CompanyComparativeGrade>> => {
		const resp = await playBackendRequestHandler(
			'companyPlayerGitHistory',
			undefined,
			undefined,
			undefined
		)
			return resp
	},
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useCompanyPlayersActivitiesGrade() {
	return useQuery({
		queryKey: [`company-players-activities-grade`],
		queryFn: async (): Promise<AxiosResponse<CompanyComparativeGrade>> => {
			const resp = await playBackendRequestHandler(
				'companyPlayersActivitiesGrade',
				undefined,
				undefined,
				undefined
			)
			return resp
		},
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useCompanyPlayersActivitiesBarChart() {
	return useQuery({
		queryKey: [`company-players-activities-bar-chart`],
		queryFn: async (): Promise<AxiosResponse<CompanyPlayersActivitiesBarChart[]>> => {
			const resp = await playBackendRequestHandler(
				'companyPlayersActivitiesBarChart',
				undefined,
				undefined,
				undefined
			)
			return resp
		},
		refetchOnWindowFocus: false,
	})
}

export function useCompanyPlayersActivitiesPieChart() {
	return useQuery({
		queryKey: [`company-players-activities-pie-chart`],
		queryFn: async(): Promise<AxiosResponse<CompanyPlayersActivitiesPieChart[]>> => {
		const resp = await playBackendRequestHandler(
			'companyPlayersActivitiesPieChart',
			undefined,
			undefined,
			undefined
		)
			return resp
	},
	refetchOnWindowFocus: false,
	})
}

export function usePlayerTotalScore() {
	return useQuery({
		queryKey: [`player-total-score`],
		queryFn: async(): Promise<AxiosResponse<Top10PlayerScore[]>> => {
		const resp = await playBackendRequestHandler(
			'leaderboard',
			undefined,
			undefined
		)
			return resp
	},
		gcTime: 60000,
		staleTime: 60000
	})
}

export function useBuild(
	gameSlug: string,
	challengeSlug: string,
) {
	const [loading, setLoading] = useState(false)
	const { wsClient } = useWebsocketContext()

	const doRequest = useCallback(async (commitId: string | null = null) => {
		function myListen(e: MessageEvent<any>) {
			try {
				const { response } = JSON.parse(e.data)
				const { buildCreated } = JSON.parse(response)
				if (buildCreated) {
					setLoading(false)
					wsClient?.current?.removeEventListener('message', myListen)
				}
			} catch {
				// Not me if im in the catch
			}
		}

		setLoading(true)
		const resp = await playBackendRequestHandler(
			'build',
			commitId ? {
				commit_id: commitId,
			} : {

			},
			undefined,
			`${gameSlug}/challenge/${challengeSlug}/test/`
		)
		if (resp.status === 201) {
			// Build Didn't Exist, lets wait async for a response on websocket
			wsClient?.current?.addEventListener('message', myListen)
		} else if (resp.status === 200) {
			// Build Did Exist, lets just stop loading
			if ((resp.data as any).BuildExists) {
				setLoading(false)
			} else {
				throw new Error('API responded with a 200 but a unknown response')
			}
		}
	}, [gameSlug, challengeSlug, wsClient])
	return { doRequest, loading }
}

export const LoadingContext = createContext({
	loading: false,
	// eslint-disable-next-line no-unused-vars
	toggleLoading: (val: boolean) => {
	},
})

export function usePageLoader() {
	return useContext(LoadingContext)
}

export const ChallengeContext = createContext({
	// eslint-disable-next-line no-unused-vars
	setChallengeAttemptDetails: (challengeAttempt: ChallengeAttempt | null) => {
	},
	// eslint-disable-next-line no-unused-vars
	setDisplayBuildOutputInTerminal: (displayBuildOutputInTerminal: DisplayTerminalInfo) => {
	},
})

export const CompanyDashboardContext = createContext({
	// eslint-disable-next-line no-unused-vars
	setOpenCompanyPlayerProfileDialogInfo: (companyPlayerGitHistoryGraphDialogInfo: CompanyPlayerActivityInfo) => {
	},
	openCompanyPlayerProfileDialogInfo: {
		isDialogShown: false, playerUsername: "", playerEmail: "", playerGuid: "",
	}
})


export const StatusContext = createContext({
	// eslint-disable-next-line no-unused-vars
	setDisplayBuildOutputInTerminal: (displayBuildOutputInTerminal: DisplayTerminalInfo) => {
	},
	// eslint-disable-next-line no-unused-vars
	setDisplayRunningTestBuildOutputInTerminal: (displayRunningTestBuildOutputInTerminal: { isShown: boolean, commitId: string }) => {
	},
	autoTestIsEnabled: localStorage.getItem("autotest") === AutoTestState.Enabled,
	// eslint-disable-next-line no-unused-vars
	setAutoTestIsEnabled: (autoTestIsEnabled: boolean) => { },
})

export const BattleContext = createContext({
	battleStartDialogInfo: {} as BattleStartDialogInfo,
	// eslint-disable-next-line no-unused-vars
	setBattleStartDialogInfo: (battleStartDialogInfo: BattleStartDialogInfo) => {
	},
})

export function useCompanyDashboardContext() {
	return useContext(CompanyDashboardContext)
}

export function useStatusContext() {
	return useContext(StatusContext)
}

export function useChallengeContext() {
	return useContext(ChallengeContext)
}

export function useBattleContext() {
	return useContext(BattleContext)
}

export const BackgroundColorContext = createContext({
	backgroundColor: '',
	/* eslint-disable no-unused-vars  */
	setBackgroundColor: (backgroundColor: string) => { },
})

export function useBackgroundColor() {
	return useContext(BackgroundColorContext)
}

export function useRetrieveRepositoryRootContentsRequest(gameSlug: string, challengeSlug: string) {
	playBackendRequestHandler(
		'retrieveRootRepositoryContentsURL',
		undefined,
		undefined,
		`${gameSlug}/challenge/${challengeSlug}/repository/root/contents`
	)
}

export function useRetrieveRepositoryDirContentsRequest(gameSlug: string, challengeSlug: string, filepath: string) {
	playBackendRequestHandler(
		'retrieveDirRepositoryContentsURL',
		{
			filepath,
		},
		undefined,
		`${gameSlug}/challenge/${challengeSlug}/repository/dir/contents`
	)
}

export function useRetrieveDirRepositoryContentsWS(gameSlug: string, challengeSlug: string): { dirContentsWSResponse: DirOrFileProps[]; loading: boolean; } {
	const [loading, setLoading] = useState(true)
	const { wsClient } = useWebsocketContext()
	const [dirContentsWSResponse, setDirContentsWSResponse] = useState([])
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			try {
				if (Array.isArray(JSON.parse(JSON.parse(e.data).response))) {
					const dirContents = JSON.parse(JSON.parse(e.data).response) // this is  commits
					if (
						dirContents && dirContents.length > 0 &&
						dirContents[0].challengeSlug === challengeSlug &&
						dirContents[0].gameSlug === gameSlug && dirContents[0].path &&
						dirContents[0].sha && dirContents[0].name && dirContents[0].type &&
						dirContents[0].repository &&
						dirContents[0].container
					) {
						const dirContentsList = dirContents.filter((item: DirOrFileProps) => item.type === "dir").sort((a: DirOrFileProps, b: DirOrFileProps) => a.name.localeCompare(b.name))
						const fileContentsList = dirContents.filter((item: DirOrFileProps) => item.type === "file").sort((a: DirOrFileProps, b: DirOrFileProps) => a.name.localeCompare(b.name))
						setDirContentsWSResponse(dirContentsList.concat(fileContentsList))
						setLoading(false)
					}
				}
			} catch {
				// Not My message if its not JSON
			}
		},
		[challengeSlug, gameSlug]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])
	return { dirContentsWSResponse, loading }
}

export function useRetrieveFileContentRequest(gameSlug: string, challengeSlug: string, filepath: string) {
	playBackendRequestHandler(
		'retrieveFileRepositoryContentsURL',
		{
			filepath
		},
		undefined,
		`${gameSlug}/challenge/${challengeSlug}/repository/file/contents`
	)
}

export function useRetrieveFileContentWS(gameSlug: string, challengeSlug: string) {
	const [loading, setLoading] = useState(true)
	const { wsClient } = useWebsocketContext()
	const [fileContentsWSResponse, setFileContentsWSResponse] = useState<FileProps>({ content: "", gameSlug: "", challengeSlug: "", sha: "", path: "", repository: "" })
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			try {
				const fileContent = JSON.parse(JSON.parse(e.data).response) // this is  commits
				if (
					fileContent.challengeSlug === challengeSlug &&
					fileContent.gameSlug === gameSlug &&
					fileContent.content &&
					fileContent.path &&
					fileContent.sha &&
					fileContent.repository
				) {
					setFileContentsWSResponse({
						...fileContent,
						content: b64DecodeUnicode(fileContent.content)
					})
					setLoading(false)
				}
			} catch {
				// Not My message if its not JSON
			}
		},
		[challengeSlug, gameSlug]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])
	return { fileContentsWSResponse, loading }
}

export function useUpdateFileRepositoryContentsRequest(gameSlug: string, challengeSlug: string, content: string, sha: string, filepath: string) {
	playBackendRequestHandler(
		'updateFileRepositoryContentsURL',
		{
			content: b64EncodeUnicode(content),
			filepath,
			sha
		},
		undefined,
		`${gameSlug}/challenge/${challengeSlug}/repository/file/contents/update`
	)
}

export function useUpdateFileRepositoryContentsWS() {
	const [isUpdated, setIsUpdated] = useState(false)
	const { wsClient } = useWebsocketContext()
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			try {
				const fileContent = JSON.parse(JSON.parse(e.data).response) // this is  commits
				if (
					fileContent.message &&
					fileContent.status === 200
				) {
					setIsUpdated(true)
				}
			} catch {
				// Not My message if its not JSON
			}
		},
		[]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])
	return { isUpdated }
}

export function useNotificationWSForPlayerWhoGotHackedTheApp(gameSlug: string, challengeSlug: string) {
	const { wsClient } = useWebsocketContext()
	const [isYourAppHacked, setIsYourAppHacked] = useState<boolean>(false)
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			try {
				const hackedInfo = JSON.parse(JSON.parse(e.data).response) // this is  commits
				// hackedInfo.is_your_app_hacked is a boolean value
				if (
					hackedInfo.is_your_app_hacked &&
					hackedInfo.game_slug === gameSlug &&
					hackedInfo.challenge_slug === challengeSlug
				) {
					setIsYourAppHacked(hackedInfo.is_your_app_hacked)
				}
			} catch {
				// Not My message if its not JSON
			}
		},
		[gameSlug, challengeSlug]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])
	return isYourAppHacked
}

export function useBattleIncidentNotificationWS(gameSlug: string, challengeSlug: string) {
	const { wsClient } = useWebsocketContext()
	const [battleIncident, setBattleIncident] = useState<BattleIncidentWSProps | null>(null)
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			try {
				const battleIncidentWSInfo = JSON.parse(JSON.parse(e.data).response) // this is  commits
				// hackedInfo.is_your_app_hacked is a boolean value
				if (
					battleIncidentWSInfo.player_username &&
					battleIncidentWSInfo.published_app.published_app_player_username &&
					battleIncidentWSInfo.published_app.published_app_id &&
					battleIncidentWSInfo.published_app.url &&
					battleIncidentWSInfo.game_slug === gameSlug &&
					battleIncidentWSInfo.challenge_slug === challengeSlug &&
					battleIncidentWSInfo.event_type
				) {
					setBattleIncident(battleIncidentWSInfo)
				}
			} catch {
				// Not My message if its not JSON
			}
		},
		[gameSlug, challengeSlug]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])

	return { battleIncident, setBattleIncident }
}

export function useBattleActivityNotificationWS(gameSlug: string, challengeSlug: string) {
	const { wsClient } = useWebsocketContext()
	const [battleActivity, setBattleActivity] = useState<BattleActivityInfo | null>(null)
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			try {
				const battleActivityWSInfo = JSON.parse(JSON.parse(e.data).response) // this is  commits
				// hackedInfo.is_your_app_hacked is a boolean value
				if (
					battleActivityWSInfo.player_username &&
					battleActivityWSInfo.battle_activity_date &&
					battleActivityWSInfo.battle_activity_type &&
					battleActivityWSInfo.game_slug === gameSlug &&
					battleActivityWSInfo.challenge_slug === challengeSlug
				) {
					setBattleActivity({
						"playerUsername": battleActivityWSInfo.player_username,
						"targetPlayerUsername": battleActivityWSInfo.target_player_username,
						"activityDate": battleActivityWSInfo.battle_activity_date,
						"battleActivityType": battleActivityWSInfo.battle_activity_type,
					})
				}
			} catch {
				// Not My message if its not JSON
			}
		},
		[gameSlug, challengeSlug]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])
	return { battleActivity, setBattleActivity }
}

export function useWSBattleGroupJoinNotificationWS(gameSlug: string, challengeSlug: string) {
	const { wsClient } = useWebsocketContext()
	const [hasPlayerJoinedToBattleGroupWS, setHasPlayerJoinedToBattleGroupWS] = useState<boolean>(false)
	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			try {
				const wsBattleGroupJoinInfo = JSON.parse(JSON.parse(e.data).response) // this is  commits
				// hackedInfo.is_your_app_hacked is a boolean value
				if (
					Object.prototype.hasOwnProperty.call(wsBattleGroupJoinInfo, "did_player_join_in_ws_battle_group") &&
					wsBattleGroupJoinInfo.game_slug === gameSlug &&
					wsBattleGroupJoinInfo.challenge_slug === challengeSlug
				) {
					setHasPlayerJoinedToBattleGroupWS(wsBattleGroupJoinInfo.did_player_join_in_ws_battle_group)
				}
			} catch {
				// Not My message if its not JSON
			}
		},
		[gameSlug, challengeSlug]
	)

	useEffect(() => {
		const ws = wsClient?.current

		ws?.addEventListener('message', myListen)

		return () => {
			ws?.removeEventListener('message', myListen)
		}
	}, [wsClient, myListen])
	return { hasPlayerJoinedToBattleGroupWS }
}

export function useCompanyPlayerGitHistoryNotificationWS() {
	const { wsClient } = useWebsocketContext()
	const [isWSConnectionEstablished, setIsWSConnectionEstablished] = useState<boolean>(false)
	const [companyPlayerGitHistoryWS, setCompanyPlayerGitHistoryWS] = useState<HeatMap[] | null>(null)

	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			const {
				heatmap
			} = JSON.parse(JSON.parse(e.data).response)  // fix for first response
			if (heatmap) {

				setCompanyPlayerGitHistoryWS(heatmap)
			}
		},
		[]
	)

	useEffect(() => {
		if (isWSConnectionEstablished) {
			// before listening to the ws event, make sure websocket is established
			const ws = wsClient?.current
			ws?.addEventListener('message', myListen)
			return () => ws?.removeEventListener('message', myListen)
		}
		return () => { }
	}, [wsClient, myListen, isWSConnectionEstablished])

	return { companyPlayerGitHistoryWS, setIsWSConnectionEstablished };
}

export function useSandboxWorkspaceBuildStatusNotificationWSForChallenge(game: string, challenge: string) {
	const { wsClient } = useWebsocketContext()
	const [isWSConnectionEstablished, setIsWSConnectionEstablished] = useState<boolean>(false)
	const [sandboxWorkspaceBuildStatusWS, setSandboxWorkspaceBuildStatusWS] = useState<WorkspaceBuildInfo | null>(null)

	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			const {
				workspaceStatus,
				workspaceUrl,
				game: gameWS,
				challenge: challengeWS,
				error
			} = JSON.parse(JSON.parse(e.data).response || JSON.parse(e.data).error || '{}')  // fix for first response
			if (gameWS === game && `${challengeWS}` === challenge) {
				setSandboxWorkspaceBuildStatusWS({
					workspaceStatus: !error ? workspaceStatus : WorkspaceStatus.Error.valueOf(),
					workspaceUrl: !error ? workspaceUrl : "",
					game,
					challenge
				})
			}
		},
		[game, challenge]
	)

	useEffect(() => {
		if (isWSConnectionEstablished) {
			// before listening to the ws event, make sure websocket is established
			const ws = wsClient?.current
			ws?.addEventListener('message', myListen)
			return () => ws?.removeEventListener('message', myListen)
		}
		return () => { }
	}, [wsClient, myListen, isWSConnectionEstablished])

	return { sandboxWorkspaceBuildStatusWS, setIsWSConnectionEstablished };
}


export function useSandboxWorkspaceBuildStatusNotificationWSForVulnerability(game: string, challenge: string) {
	const { wsClient } = useWebsocketContext()
	const [sandboxWorkspaceBuildStatusWS, setSandboxWorkspaceBuildStatusWS] = useState<WorkspaceBuildInfo | null>(null)

	const myListen = useCallback(
		(e: MessageEvent<any>) => {
			const {
				workspaceStatus,
				workspaceUrl,
				game: gameWS,
				challenge: challengeWS,
				error,
				message
			} = JSON.parse(JSON.parse(e.data).response || JSON.parse(e.data).error || '{}')  // fix for first response
			if (gameWS === game && `${challengeWS}` === challenge) {
				setSandboxWorkspaceBuildStatusWS({
					workspaceStatus: !error ? workspaceStatus : WorkspaceStatus.Error.valueOf(),
					workspaceUrl: !error ? workspaceUrl : "",
					game,
					challenge,
					message: error ? message : ""
				})
			}
		},
		[game, challenge]
	)

	useEffect(() => {
		const ws = wsClient?.current
		ws?.addEventListener('message', myListen)
		return () => ws?.removeEventListener('message', myListen)
	}, [wsClient, myListen])

	return { sandboxWorkspaceBuildStatusWS };
}